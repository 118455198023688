import React from 'react'

const Roadmap = () => {
  return (
    <div >
    <div className="container hero__inner-cont">
    
            
                <div className="  hero__inner-cont__left ">
                    <h1 className="">How to Buy $Sugs</h1>
                       <button className="btn hero-btn">
                        <a href="/" target="_blank" rel="noopener noreferrer">BUY $Sugs</a>
                    </button>
                </div>
                
                <div className="  hero__inner-cont__right">
                    <li>1</li>
                     <li>2</li>
                      <li>3</li>
                       <li>4</li>
                                       
                </div>
         
    </div>
</div>
  )
}

export default Roadmap